<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            VENDEDOR: {{seller.FirstName }} {{ seller.LastName }}
            <template v-slot:actions>
                <v-icon color="primary" @click.native.stop="reloadView++">
                    mdi-refresh
                </v-icon>
                <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                    mdi-close
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>

                    <v-tab href="#tab-1">
                        GESTIÓN DE VENDEDOR
                        <v-icon>mdi-account-box</v-icon>
                    </v-tab>

                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat class="py-4" style="padding: 14px;">
                            <v-card-text class="white">
                                <ManageSeller :key="reloadView" :win="win" :seller="seller"></ManageSeller>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import ManageSeller from './ManageSeller';

    export default {
        name: "SellerData",
        props: ['win'],
        components: {
            ManageSeller, 
        },
        data() {
            return {
                tab: null,
                seller: null,
                reloadView: 0
            }
        },
        mounted() {
            this.seller = this.win.meta.seller;
        },
        methods: {
            reloadData(payload) {
                this.$emit('reloadData', payload)
            }
        }

    }
</script>

<style scoped>

</style>
