<template>
  <v-form class="position-relative" v-model="valid">
    <block v-if="loading"></block>

    <v-row class="ml-2 mr-2">
      <v-col v-if="!user" cols="12" class="tabsNh">
        <template v-if="seller">
          <v-col cols="12" md="12" class="d-flex justify-space-between">
            <v-btn small color="error" @click.stop="deleteSellerOption" dense>
              <v-icon> mdi-delete </v-icon>
            </v-btn>
            <v-btn small class="btnsave" @click.prevent="updateSeller" dense>
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" md="12" class="d-flex justify-end">
            <v-btn small class="btnsave" @click.prevent="saveSeller" dense>
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </v-col>
        </template>
      </v-col>
    </v-row>
    <v-row style="margin-top: 1.8rem; margin-bottom: 1rem" class="ml-2 mr-2">
      <v-col cols="12">
        <div class="box">
          <v-row>
            <v-col cols="12" md="3" style="margin-top: 1rem; margin-bottom: 1rem">
              <h3 v-if="!user">Datos Principales</h3>
              <h2 v-else>Datos del vendedor</h2>
            </v-col>
            <v-col v-if="!seller" cols="12" sm="12" md="12">
              <label>Nota: los apartados con (*) son datos requeridos</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.firstName" :rules="[(v) => !!v || $t('required')]" required
                label="Nombres*"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.lastName" :rules="[(v) => !!v || $t('required')]" required
                label="Apellidos*"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.email" label="Correo eléctronico"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select outlined dense :items="['Masculino', 'Femenino']" label="Sexo" v-model="form.gender"
                attach></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.mobile" label="Celular"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <date-picker label="Fecha nacimiento" @dateSelected="(param) => (form.birthday = param)"
                :value="form.birthday" v-model="form.birthday"></date-picker>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.contact" label="Contacto emergencia"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.phoneNumber" label="Teléfono emergencia"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.dui" label="DUI*" :rules="[(v) => !!v || $t('required')]"
                required></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete outlined dense :items="countryList" attach item-text="name" v-model="form.country" required
                :rules="required" @change="getStates" label="Pais*"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete outlined dense v-if="form.country == 'El Salvador'" attach :items="departments"
                item-text="Valores" item-value="Codigo" :rules="[(v) => !!v || $t('required')]" required
                v-model="form.department" label="Estado/Departamento*"></v-autocomplete>
              <v-autocomplete outlined dense attach v-else :items="stateList" item-text="name" @change="getCities"
                v-model="form.department" label="Estado/Departamento*" :rules="[(v) => !!v || $t('required')]" required>
                <template v-slot:no-data>
                  <div class="px-4">{{ noDataLabelStates }}</div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete outlined dense :items="municipalities" attach item-text="Valores" item-value="Codigo"
                v-if="form.country == 'El Salvador'" :readonly="!form.department" v-model="form.municipality"
                label="Ciudad*" :rules="[(v) => !!v || $t('required')]" required></v-autocomplete>
              <v-autocomplete outlined dense attach v-else :items="cityList" item-text="name"
                :rules="[(v) => !!v || $t('required')]" required :readonly="!form.department" v-model="form.municipality"
                label="Ciudad*">
                <template v-slot:no-data>
                  <div class="px-4">{{ noDataLabelCities }}</div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-4" style="margin-bottom: 1rem">
              <h3>Datos de cuenta</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" v-if="form.typeuser != 'Administrador del sistema'">
              <span class="fixing">
                <v-autocomplete outlined dense :items="businessList" item-text="BusinessName" item-value="ID_Business"
                  attach :rules="[(v) => !!v || $t('required')]" label="Negocio del vendedor*" v-model="form.userbusiness"
                  required></v-autocomplete>
              </span>
            </v-col>
            <v-col v-if="(user == undefined ||
              form.typeuser == 'Vendedor' ||
              form.typeuser == 'Administrador de negocio') &&
              form.typeuser != 'Administrador del sistema'
              " cols="12" md="3" class="d-flex align-end">
              <span class="fixing">
                <v-autocomplete outlined dense :items="branchesList" item-text="BranchName" item-value="ID_Branch"
                  label="Sucursal del vendedor*" attach v-model="form.userbranch" required @click="fillInBranches()"
                  :rules="[(v) => !!v || $t('required')]"></v-autocomplete>
              </span>
            </v-col>

            <v-col v-if="type_user == 'ROLE_ROOT'" cols="12" md="3" class="d-flex align-end">
              <span class="fixing">
                <v-autocomplete outlined dense :items="[
                  'Administrador del sistema',
                  'Administrador de negocio',
                  'Vendedor',
                ]" label="Tipo de vendedor" attach v-model="form.typeuser" required
                  :rules="[(v) => !!v || $t('required')]"></v-autocomplete>
              </span>
            </v-col>

            <v-col cols="12" md="3" v-if="form.typeuser == 'Vendedor'">
              <span class="fixing">
                <v-autocomplete outlined dense :items="[
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                ]" label="Punto de venta *" attach v-model="form.pointsale" required
                  :rules="[(v) => !!v || $t('required')]"></v-autocomplete>
              </span>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-btn v-if="user" @click="showProducts = !showProducts" class="btncontinue">
      Continuar
    </v-btn>

    <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </alerts>
  </v-form>
</template>

<script>
import Vue from "vue";
import DatePicker from "@/components/DatePicker";
import Block from "@/components/Block";
import Alerts from "@/components/Alerts";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ManageSeller",
  components: { Block, DatePicker, Alerts },
  props: ["seller", "user", "win"],
  data: () => ({
    validSeller: true,
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    form: { status: true },
    valid: false,
    tab: null,
    required: [(v) => !!v || this.$t('required')],
    fieldRules: [(v) => !!v || "Campo obligatorio"],
    nameRules: [(v) => !!v || "Name is required"],
    mobileRules: [(v) => !!v || "Mobile is required"],
    userNameRules: [(v) => !!v || "User name is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    url: null,
    loading: false,
    deleteDialog: false,
    sellofname: null,
    businessList: [],
    branchesList: [],
    requiredRule: [(value) => !!value || "Required."],
    type_user: null,
    business_user: null,
    stateList: [],
    cityList: [],
    noDataLabelStates: "Seleccione un país",
    noDataLabelCities: "No hay ciudades disponibles",
    currentuser: null,
  }),
  mounted() {
    if (!this.seller) {
      this.sysParametersDefault.forEach((element) => {
        if (element.ParameterName == "country") {
          this.form.country = element.ParameterValue;
        } else if (element.ParameterName == "department") {
          this.form.department = element.ParameterValue;
        } else if (element.ParameterName == "city") {
          this.form.municipality = element.ParameterValue;
        }
      });
    }
    
    this.currentuser = JSON.parse(localStorage.getItem("user")).id;
    this.type_user = JSON.parse(localStorage.getItem("user")).roles[0];
    this.business_user = JSON.parse(localStorage.getItem("user")).business;
    this.fillInBussiness();

    if (this.seller) {
      for (var el in this.seller) {
        if (el == "EmergencyContact") {
          Vue.set(this.form, "contact", this.seller[el]);
        } else if (el == "EmergencyContactPhone") {
          Vue.set(this.form, "phoneNumber", this.seller[el]);
        } else if (el == "DateOfBirth") {
          Vue.set(this.form, "birthday", this.seller[el]);
        } else if (el == "Roles") {
          if (this.seller[el][0]["ID_Role"] == 1) {
            Vue.set(this.form, "typeuser", "Administrador del sistema");
          } else if (this.seller[el][0]["ID_Role"] == 2) {
            Vue.set(this.form, "typeuser", "Administrador de negocio");
          } else {
            Vue.set(this.form, "typeuser", "Vendedor");
          }
        } else if (el == "ID_Business") {
          Vue.set(this.form, "userbusiness", parseInt(this.seller[el]));
          console.log("negocio y branch ", this.seller[el], this.form.userbusiness);
        } else if (el == "ID_Branch") {
          Vue.set(this.form, "userbranch", parseInt(this.seller[el]));
          console.log("negocio y branch ", this.seller[el], this.form.userbranch);
        } else if (el == "PointSale") {
          Vue.set(this.form, "pointsale", this.seller[el].toString());
        } else {
          Vue.set(this.form, el[0].toLowerCase() + el.slice(1), this.seller[el]);
        }
      }
    }
    this.fillInBranches();

    if (this.form.country != "El Salvador") {
      this.getStates();
      this.getCities();
    }
  },

  computed: {
    ...mapGetters(["getCatalogMH"]),
    ...mapState("sysparameters", {
      sysParametersDefault: (state) => state.parameters,
    }),
    departments() {
      return this.getCatalogMH("Departamento");
    },
    municipalities() {
      const municipalities = this.getCatalogMH("Municipio");
      if (this.form.department) {
        const department = municipalities.filter(
          (mun) => mun.Departamento == this.form.department
        );
        return department;
      }
      return [];
    },
    countryList() {
      return this.$store.getters.getCountries;
    },
    checkcountry() {
      if (this.form.country == "El Salvador") return true;
      else return false;
    },
  },
  methods: {
    async getStates() {
      if (this.form.country == "El Salvador") return [];
      if (this.form.country) {
        this.noDataLabelStates = "Cargando...";
        this.stateList = [];

        this.stateList = await this.$API.general.getStates(this.form.country);
        console.log(this.stateList);
        this.noDataLabelStates = "No hay Estados disponibles";
      }
    },
    async getCities() {
      if (this.form.country == "El Salvador") return [];
      console.log(this.form.department);
      if (this.form.department) {
        this.noDataLabelCities = "Cargando...";
        this.cityList = [];

        this.cityList = await this.$API.general.getCities(this.form.department);
        console.log(this.cityList);
        this.noDataLabelCities = "No hay Ciudades disponibles";
      }
    },

    closeAlert() {
      this.alert.show = false;

      if (this.alert.options === "sellerreload") {
        this.$store.dispatch("reloadData", "users");
        this.$store.dispatch("addWindow", {
          name: "SellerList",
          component: "SellerList",
          unique: false,
        });
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletecustomer") {
        this.deleteSeller(this.sellerToDelete);
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    fillInBussiness() {
      this.$API.business.businessList().then((response) => {
        try {
          if (this.business_user != "none" && this.type_user == "ROLE_ADMIN") {
            for (var i = 0; i < response.length; i++) {
              if (response[i]["BusinessName"] == this.business_user) {
                this.businessList.push(response[i]);
              }
            }
          } else {
            this.businessList = response;
          }
        } catch (error) {
          console.log(error);
        }
      });
    },
    fillInBranches() {
      this.$API.branches.findBranches(this.form.userbusiness).then((data) => {
        this.branchesList = data;
      });
    },
    saveSeller() {
      if (!this.valid) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe llenar todos los campos requeridos"
        );
        return;
      }
      this.loading = true;
      if (this.type_user == "ROLE_ROOT") {
        this.businessList.forEach((element) => {
          if (element.ID_Business == this.form.userbusiness) {
            this.form.businesstype = element.BusinessType;
          }
        });
      } else {
        this.form.businesstype = JSON.parse(localStorage.getItem("user")).businesstype;
      }
      this.form.ID_User_Insert = this.currentuser;
      this.$API.sellers
        .createSeller(this.form)
        .then(() => {
          this.loading = false;
          this.showAlert(
            "success",
            "Vendedor creado",
            "El vendedor se ha creado correctamente",
            "sellerreload"
          );
          this.form = { status: true };

          this.url = null;
        })
        .catch(() => {
          this.loading = false;
          this.showAlert("danger", "Error", "El vendedor no se ha creado correctamente");
        });
    },
    updateSeller() {
      if (!this.valid) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe llenar todos los campos requeridos"
        );
        return;
      }
      this.loading = true;
      this.form.ID_User_Update = this.currentuser;
      this.$API.sellers
        .updateSeller(this.form.iD_Seller, this.form)
        .then(() => {
          this.loading = false;
          this.showAlert(
            "success",
            "Vendedor  Actualizado",
            "Datos de vendedor agregados correctamente",
            "sellerreload"
          );
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.showAlert(
            "danger",
            "Error",
            "El vendedor no se ha podido actualizar de manera correcta"
          );
        });
    },
    deleteSeller() {
      this.$API.sellers.deleteSeller(this.form.iD_Seller)
        .then(() => {
          this.showAlert("success", "Vendedor eliminado", "El vendedor ha sido eliminado correctamente");
          this.getAllSellers()
          this.closeDialog()
        })
    },
    closeDialog(payload) {
      this.deleteDialog = false;
      if (payload.isDeleted) {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("reloadData", "users");
      }
    },
    deleteSellerOption() {
      this.showAlert('question', 'Eliminar',
        '¿Está seguro que desea eliminar este vendedor?', 'deletecustomer'
      );
    }
  },
};
</script>

<style scoped>
.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.btncontinue {
  float: right !important;
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}
</style>
